import axios from 'axios';
const url = 'loans/Api/ContractTemplates/';

export default {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async get_allTemplates(_, payload) {
            try {
                const response = await axios.get(
                    `${url}GetAll?Page=${payload.page}&RecordsPerPage=${payload.records}&SortDirection=${payload.sortDirection ?? ''}&SortProperty=${payload.sortProperty ?? ''}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting templates', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_allTemplatesDDL() {
            try {
                const response = await axios.get(`${url}GetAllDDL`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting templates', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_templateById(_, id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting template', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async create_template(_, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    ...payload,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating template', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_template(_, payload) {
            try {
                const response = await axios.put(`${url}Update/${payload.id}`, {
                    ...payload.data,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating template', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};

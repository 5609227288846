import axios from 'axios';
const url = 'general/Api/ScreeningInstitutions/';
export default {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async get_institutions(_, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening institutions', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_institutionById(_, id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening institution by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_institutionsDDL() {
            try {
                const response = await axios.get(`${url}GetAllDDL`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening institutions', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async create_institution(_, payload) {
            try {
                const response = await axios.post(`${url}Add`, payload);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating screening institution', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_institution(_, payload) {
            try {
                const response = await axios.put(`${url}Update/${payload.id}`, payload.data);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating screening institutions', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};

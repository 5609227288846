export default [
    {
        path: 'general',
        redirect: { name: 'General Banks' },
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
            {
                name: 'General Banks',
                path: 'banks',
                component: () => import('@/views/general/Banks'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Banks-GetAll'],
                },
            },
            {
                name: 'Screening Institutions',
                path: 'screening-institutions',
                component: () => import('@/views/general/ScreeningInstitutions'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Banks-GetAll'],
                },
            },
            {
                name: 'Countries',
                path: 'countries',
                component: () => import('@/views/general/Countries'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Countries-GetAll'],
                },
            },
            {
                name: 'Cities',
                path: 'cities',
                component: () => import('@/views/general/Cities'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Cities-GetAll'],
                },
            },
            {
                name: 'States',
                path: 'states',
                component: () => import('@/views/general/States'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/States-GetAll'],
                },
            },
        ],
    },
];

// GET
// /Api/ContractDocumentSignProcess/HelpCheck

// GET
// /Api/ContractDocumentSignProcess

// POST
// /Api/ContractDocumentSignProcess/GenerateContract
import axios from 'axios';
const url = 'loans/Api/ContractDocumentSignProcess/';

export default {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async create_signProcess(_, id) {
            try {
                const response = await axios.post(`${url}GenerateContract`, {
                    loanApplicationId: id,
                });
                this._vm.$bvToast.toast('Sign process created successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating document sign process', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_signProcess() {
            try {
                const response = await axios.get(`${url}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting sign process', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async printContract(_, id) {
            try {
                const response = await axios.post(`${url}PrintContract`, id, {
                    headers: {
                        accept: 'text/plain',
                        'Content-Type': 'application/json',
                    },
                });
                this._vm.$bvToast.toast('Contract printed successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error printing contract', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async uploadContractSigned(_, id) {
            try {
                const response = await axios.post(`${url}UploadContractSigned`, id, {
                    headers: {
                        accept: 'text/plain',
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error uploading signed contract', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};

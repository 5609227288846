export default [
    {
        name: 'Users',
        path: 'security/users',
        component: () => import('@/views/security/users/Users'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Users-GetAll'],
        },
    },
    {
        name: 'Roles',
        path: 'security/roles/list',
        component: () => import('@/views/security/roles/Roles'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Roles-GetAll'],
        },
    },
    {
        name: 'ViewRoles',
        path: 'security/roles/list/:id',
        component: () => import('@/views/security/roles/ViewRole'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Roles-GetAll'],
        },
    },
    {
        name: 'Update role',
        path: 'security/roles/list/:id/update',
        component: () => import('@/views/security/roles/HandleRole'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Roles-GetAll'],
        },
    },
    {
        name: 'CreateRoles',
        path: 'security/roles/create',
        component: () => import('@/views/security/roles/HandleRole'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Roles-GetAll'],
        },
    },
];

import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            screening: [],
            screeningByBank: [],
            totalScreeningByBank: 0,
            totalScreening: 0,
        };
    },
    getters: {
        getScreening(state) {
            return state.screening;
        },
        getTotalScreening(state) {
            return state.totalScreening;
        },
        getScreeningByBank(state) {
            return state.screeningByBank;
        },
        getTotalScreeningByBank(state) {
            return state.totalScreeningByBank;
        },
    },
    mutations: {
        SET_SCREENING(state, screening) {
            state.screening = screening;
        },
        SET_TOTAL_SCREENING(state, data) {
            state.totalScreening = data;
        },
        SET_SCREENING_BY_BANK(state, screening) {
            state.screeningByBank = screening;
        },
        SET_TOTAL_SCREENING_BY_BANK(state, data) {
            state.totalScreeningByBank = data;
        },
    },
    actions: {
        async get_screeningByCustomerId({ commit }, payload) {
            try {
                const response = await axios.get(`customers/Api/ScreeningVerification/GetByCustomerId/${payload}`);
                commit('SET_SCREENING', response.data.dataResult);
                commit('SET_TOTAL_SCREENING', response.data.dataRecords);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening verifications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_screeningByLoanAppId({ commit }, payload) {
            try {
                const response = await axios.get(
                    `customers/Api/ScreeningVerification/GetByLoanApplicationId/${payload}`,
                );
                commit('SET_SCREENING', response.data.dataResult);
                commit('SET_TOTAL_SCREENING', response.data.dataRecords);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening verifications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_screeningVerifications() {
            try {
                const response = await axios.get(`customers/Api/ScreeningVerification/GetAll`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening verifications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async add_screeningVerification({ rootGetters }, payload) {
            try {
                const response = await axios.post(`customers/Api/ScreeningVerification/AddObligation`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Screening verification added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding screening verifications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_screeningBankRecords({ rootGetters }, payload) {
            try {
                const response = await axios.post(`customers/Api/ScreeningVerification/AddScreeningBankRecords`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Screening bank record added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding screening bank record', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_screeningStatus({ rootGetters }, payload) {
            try {
                const response = await axios.put(`customers/Api/ScreeningVerification/UpdateStatus`, {
                    id: payload.id,
                    isActive: payload.isActive,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Screening verification updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating screening verifications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_fromVerificationForm({ rootGetters }, payload) {
            try {
                let data = payload;
                data.modifiedBy = rootGetters['auth/getUser'].Id;
                const response = await axios.put(`customers/Api/ScreeningVerification/Update`, payload);
                this._vm.$bvToast.toast('Screening verification updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating screening verifications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async send_Emails(_, id) {
            try {
                const response = await axios.put(`customers/Api/ScreeningVerification/SendEmails`, {
                    CustomerId: id,
                });
                this._vm.$bvToast.toast('Emails sent successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error sending emails', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_bankInformation(_, payload) {
            try {
                const response = await axios.get(
                    `customers/Api/ScreeningVerification/GetBankInformation?ID=${payload.id}&GUID=${payload.guid}`,
                );
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting bank information', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        // /ScreeningVerification/GetAllPendingRequests

        async get_pendingRequests() {
            try {
                const response = await axios.get(`customers/Api/ScreeningVerification/GetAllPendingRequests`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting pending requests', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        // /ScreeningVerification/SubmitToPendingRequests
        async submit_toPendingRequests({ rootGetters }, options) {
            try {
                const response = await axios.put(`customers/Api/ScreeningVerification/SubmitToPendingRequests`, {
                    ...options,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Records submitted to requests successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error submitting records', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        // /ScreeningVerification/SubmitToPendingVerification
        async submit_toPendingVerification({ rootGetters }, payload) {
            try {
                const response = await axios.put(`customers/Api/ScreeningVerification/SubmitToPendingVerification`, {
                    screeningIds: payload,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Records submitted to verification successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error submitting records', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_myScreeningHistory(_, payload) {
            try {
                const response = await axios.get(`customers/Api/ScreeningVerification/my-screening-history`, {
                    page: payload.page,
                    recordsPerPage: payload.recordsPerPage,
                    sortDirection: payload.sortDirection,
                    sortProperty: payload.sortProperty,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening history', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_allScreeningHistory(_, payload) {
            try {
                const response = await axios.get(`customers/Api/ScreeningVerification/all-screening-history`, {
                    page: payload.page,
                    recordsPerPage: payload.recordsPerPage,
                    sortDirection: payload.sortDirection,
                    sortProperty: payload.sortProperty,
                });
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting screening history', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};

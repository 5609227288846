import axios from 'axios';

const url = 'auth/Api/Roles/';

export default {
    namespaced: true,
    state() {
        return {
            roles: [],
            totalRoles: 0,
        };
    },
    getters: {
        getRoles(state) {
            return state.roles;
        },
        getTotalRoles(state) {
            return state.totalRoles;
        },
    },
    mutations: {
        SET_ROLES(state, roles) {
            state.roles = roles;
        },
        SET_TOTAL_ROLES(state, totalRoles) {
            state.totalRoles = totalRoles;
        },
    },
    actions: {
        async get_allRoles({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_ROLES', response.data.dataResult);
                commit('SET_TOTAL_ROLES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting roles', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_role(_, id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error getting role', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async add_role(_, role) {
            try {
                const response = await axios.post(`${url}Add`, role);
                this._vm.$bvToast.toast('Role added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error adding role', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_role(_, role) {
            try {
                const response = await axios.put(`${url}Update`, role);
                this._vm.$bvToast.toast('Role updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error updating role', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_roleStatus({ rootGetters }, role) {
            try {
                const response = await axios.put(`${url}UpdateStatus`, {
                    Id: role.id,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: role.isActive,
                });
                this._vm.$bvToast.toast('Role status updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error updating role status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async add_roleAuthorization({ rootGetters }, roleAuthorization) {
            try {
                const response = await axios.post(`${url}AddAuthorization`, {
                    Role_Id: roleAuthorization.roleId,
                    Auth_Ids: roleAuthorization.authIds,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Role authorization added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error adding role authorization', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async remove_roleAuthorization(_, roleAuthorization) {
            try {
                const response = await axios.put(`${url}RemoveAuthorizations`, {
                    Role_Id: roleAuthorization.roleId,
                });
                this._vm.$bvToast.toast('Role authorization removed successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error removing role authorization', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};

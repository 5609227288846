export default [
    {
        path: 'contracts',
        redirect: { name: 'Contracts' },
        component: () => import('@/layouts/ApplicationLayout'),
        children: [
            {
                name: 'Templates',
                path: 'templates',
                component: () => import('@/views/contracts/Templates'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Contracts-GetAll'],
                },
            },
        ],
    },
];

export default [
    {
        path: 'loans',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
            {
                name: 'All',
                path: 'all',
                component: () => import('@/views/loans/LoanList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetAll'],
                },
            },
            {
                name: 'Active Loan Detail',
                path: 'all/:loan',
                component: () => import('@/views/loans/LoanDetail'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetByLoanId'],
                },
            },

            {
                name: 'Active in Arrears',
                path: 'active-in-arrears',
                component: () => import('@/views/loans/LoanList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetAll'],
                },
            },
            {
                name: 'Active Loans',
                path: 'active',
                component: () => import('@/views/loans/LoanList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetAll'],
                },
            },

            {
                name: 'Approved Loans',
                path: 'approved',
                component: () => import('@/views/loans/LoanList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetAll'],
                },
            },
            {
                name: 'Pending Approval',
                path: 'pending-approval',
                component: () => import('@/views/loans/LoanList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetAll'],
                },
            },

            {
                name: 'Closed Loans',
                path: 'closed',
                component: () => import('@/views/loans/LoanList'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Loans-GetAll'],
                },
            },
        ],
    },
];
